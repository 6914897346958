:root {
  --color-vivanta-1: #694aff;
  --color-vivanta-2: #3343D6;
  --color-vivanta-gray: #5E5E5E;
  --color-vivanta-black: #212121;
  --color-vivanta-white: #FFFFFF;
}

.navbar.bg-vivanta {
  background-color: var(--color-vivanta-1);
  color: var(--color-vivanta-white);
}

.navbar.bg-vivanta .nav-link {
  color: var(--color-vivanta-white);
}

.navbar.bg-vivanta .navbar-toggler {
  color: var(--color-vivanta-1);
}
.navbar.bg-vivanta .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,1.0)' strokeWidth='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.vivanta-header-logo {
  padding-right: 16px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
